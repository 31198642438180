import React, { useState, useEffect } from "react";
import "./MessageAction.scss";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import Helper from '../../../helper/HelperChat';
import { isOtherAgent } from '../../../helper/HelperGeneral';
import ForwardIcon from '../../../assets/img/message-action/forward.svg';
import ReplyIcon from '../../../assets/img/message-action/reply.svg';
import CopyIcon from '../../../assets/img/message-action/copy.svg';
import InfoIcon from '../../../assets/img/message-action/info.svg';
import TripleDotIcon from '../../../assets/img/message-action/triple-dot.svg';
import { setPopupMessageInfo } from '../../../redux/actions/reduxActionPopupMessageInfo';
import { connect } from 'react-redux';

const MessageAction = (props) => {
    let { _className, singleChatDataProps, hideReply, hideForward, hideCopy, replyAction, forwardAction, caseDetailData, myAgentData, setPopupMessageInfo } = props;

    let [showMessageAction, setShowMessageAction] = useState(false);

    let toggleMessageAction = () => {
        // let chatRoomTarget = document.querySelectorAll(".chat-room-main-content");

        // if(chatRoomTarget.length > 0) {
        //     if(showMessageAction) {
        //         chatRoomTarget[0].style.overflow = "auto";
        //     }else {
        //         chatRoomTarget[0].style.overflow = "hidden"; 
        //     }
        // }

        setShowMessageAction(!showMessageAction);
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={showMessageAction}
                toggle={toggleMessageAction}
                className={`message-action-dropdown ${_className} ${showMessageAction ? "message-action-show" : ""}`}>
                <DropdownToggle>
                    <img src={TripleDotIcon} alt="" className="triple-dot-wrapper" />
                </DropdownToggle>

                <DropdownMenu>
                    {!hideForward &&
                        <DropdownItem onClick={forwardAction}>
                            <img src={ForwardIcon} alt="" />
                            Forward
                        </DropdownItem>
                    }

                    {!hideReply &&
                        <DropdownItem onClick={replyAction}>
                            <img src={ReplyIcon} alt="" />
                            Reply
                        </DropdownItem>
                    }
                    
                    {!hideCopy &&
                        <DropdownItem onClick={() => Helper.copyToClipBoard(singleChatDataProps.body)}>
                            <img src={CopyIcon} alt="" />
                            Copy
                        </DropdownItem>
                    }

                    {(
                        caseDetailData &&
                        (caseDetailData.case.medium === 'whatsapp' || caseDetailData.case.medium === 'whatsappba') &&
                        (!isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), myAgentData))
                    ) &&
                        <DropdownItem 
                            onClick={() => {
                                console.log("here")
                                console.log({
                                    isShow: true,
                                    message: singleChatDataProps
                                })
                                setPopupMessageInfo({
                                    isShow: true,
                                    message: singleChatDataProps
                                })
                            }}>
                            <img src={InfoIcon} alt="" />
                            Message Info
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};


const mapStateToProps = state => ({
    caseDetailData: state.caseDetailData,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setPopupMessageInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageAction);