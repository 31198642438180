import React from "react";
import "./PopUpLoading.scss";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ButtonWithLoadingOrIcon from "../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let PopupLoading = (props) => {
  let {
    isOpen,
    toggle,
    className,
    title,
    description,
    buttonText,
    buttonAction,
    buttonClass
  } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={false}
        className={`modal-loading ${className}`}
      >
        <ModalBody>
          <div className='lds-ring'>
            <div /><div /><div /><div />
          </div>
          <b className="loading-title"> {title}</b>
          <p className="loading-description" dangerouslySetInnerHTML={{__html: description}} />
        </ModalBody>

        {
          buttonText &&
          <ModalFooter>
            <ButtonWithLoadingOrIcon 
              text={buttonText}
              className={buttonClass ? buttonClass : "red-button cancel-button main-button-48"}
              onClickAction={buttonAction}
            />
          </ModalFooter>
        }
      </Modal>
    </div>
  )
}

export default PopupLoading;
